import React from 'react'
import styled from 'styled-components'
import Container from '../ui/Container'
import Button from '../ui/Button'
import productImage from '../../images/product-box.png'

const Wrapper = styled.div`
    background: linear-gradient(114.43deg, #AABAF9 40.9%, #EFF1FA 100%);
    text-align: center;
    margin: 60px auto 0;
    padding: 0 0 20px;

    @media(min-width: 600px) {
        text-align: left;
        padding: 50px 20px;
    }
`

const FirstSection = styled.div`
    @media(min-width: 600px) {
        width: 50%;
    }
`

const SecondSection = styled.div`
    @media(min-width: 600px) {
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
`
const Sections = styled.div`
    @media(min-width: 600px) {
        display: flex;
    }
`

const Phones = styled.img`
    width: 80%;
    margin: 30px auto 0;
    @media(min-width: 600px) {
        width: 100%;
        margin: 0;
    }
`
const Title = styled.h4`
    padding-top: 60px;
    font-weight: 600;
    font-style: italic;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -1px;
    color: #FFFFFF;
    
    @media(min-width: 600px) {
        font-size: 30px;
    }
`

const GetStartedButton = styled(Button)`
    background: #59E3B3;
    margin: 40px auto 0;
`

const List = styled.ul`
    color: #fff;
    list-style: circle;
    text-align: left;
    padding: 0 0 0 20px;
`
const ListItem = styled.li`
    margin: 30px 0 0;
`

function CtaSteps() {

    return <Wrapper>
        <Container>
            <Sections>
                <FirstSection>
                    <Title>Let’s take your first step towards recovery, together</Title>
                    <List>
                        <ListItem>An assigned prescribing provider and Care Manager</ListItem>
                        <ListItem>Prescription and medication delivery</ListItem>
                        <ListItem>Unlimited messaging with Care Manager</ListItem>
                        <ListItem>Ongoing check-ins with your provider and Care Manager</ListItem>
                    </List>
                    <GetStartedButton to={process.env.GATSBY_START_URL}>Get started for only $30</GetStartedButton>
                </FirstSection>
                <SecondSection>
                    <Phones src={productImage} />
                </SecondSection>
            </Sections>

        </Container>
    </Wrapper>
}

export default CtaSteps