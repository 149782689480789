import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import CtaSteps from "../components/sections/CtaSteps"
import PostPreviewList from "../components/ui/PostPreviewList"
import ItalicHeading from "../components/ui/ItalicHeading"
import Container from "../components/ui/Container"
import styled from "styled-components"
import { edgeToPostPreview } from '../lib/utils'
import { graphql } from 'gatsby'

const Wrapper = styled.div`
    padding: 60px 0 100px;

    ${PostPreviewList} {
      margin-top: 60px;
    }
`

const BlogPage = ({ data }) => {

  const previews = data.all.edges.map(edge => edgeToPostPreview({ edge }))

  return <Layout>
    <SEO title="Blog" />
    <Container>
      <Wrapper>
        <ItalicHeading>Expert tips</ItalicHeading>
        <PostPreviewList posts={previews} />
      </Wrapper>
    </Container>
    <CtaSteps />
  </Layout>
}

export const query = graphql`
  query BlogPageQuery {

    all: allPrismicPost(sort: {fields: data___date, order: DESC}) {
      edges {
        node {
          data {
            title {
              text
            }
            previewimage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 834) {
                    src
                  }
                }
              }
            }        
            author_name {
              text
            }
            date(formatString: "MM/DD/YYYY")
          }
          prismicId
          uid
        }
      }
    }
}
`

export default BlogPage